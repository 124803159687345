import './App.scss';
import CompareInputFilesSection from './Components/CompareInputFilesSection';







function App() {
  
  return (
    <div className="App">
      <CompareInputFilesSection />
    </div>
  );
}

export default App;

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';


function DatePicker(props) {
  const { startDate, setStartDate } = props;


  return (
    <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} />

  );
}

export default DatePicker;

import { compact, find, omit, uniq } from 'lodash';
import React from 'react';
import XLSX from 'xlsx';
import docsConfig from '../General/docs_config.json';
import masterIds from '../General/master_ids.json';





function CompareData(props) {

  const { newFileData, masterFileData} = props;
  
  const newFileDataConfig = find(docsConfig,{id:'new'})
  const { correctColumns } = newFileDataConfig;

  const masterFileDataConfig = find(docsConfig,{id:'master'})
  const { columnsNameByOutputFile } = masterFileDataConfig;

  const updateMasterFileData = () => {

    let updatedMasterFileData = masterFileData.map((d)=>{

      let updateDatum = {};

      correctColumns.forEach((c)=>{
        let keysByDoc = find(columnsNameByOutputFile,{outputFileID: c})
        if (keysByDoc !== undefined) {
          const { id } = keysByDoc;
          updateDatum[c] = d[id];
        } else {
          updateDatum[c] = '';
        }
      })

      return updateDatum;
    })

    return updatedMasterFileData;
  }

  const updateFirstNameAndLastName = data => {
    return data.map((d)=>{

      let updatedDatum = d;

      const { NOME, COGNOME } = updatedDatum;

      const lowercaseName = NOME.toLowerCase();
      const lowercaseLastName = COGNOME.toLowerCase();

      const updateFirstLettersRegEx = /(^|\/|-|')(\S|^\w{1})|(\s+\w{1})/g;


      updatedDatum['NOME'] = lowercaseName.replace(updateFirstLettersRegEx, letter => letter.toUpperCase());
      updatedDatum['COGNOME'] = lowercaseLastName.replace(updateFirstLettersRegEx, letter => letter.toUpperCase());

      return updatedDatum;
    })
  }

  const filterByCourseID = data => {

    let errors = [];
    
    let dataWithIDs = data.map((datum)=>{

      const { EMAIL } = datum;
      
      let courseInfo = find(masterIds,{ARGOMENTO: datum.ARGOMENTO});
      
      let id = '';
      
      if (courseInfo){
        
        const { SIGLA_2 } = courseInfo;
        id = SIGLA_2.concat(EMAIL);
      } else {

        console.log(`Error! courseInfo of ${datum.ARGOMENTO} not found! email: ${EMAIL}`)

        errors.push(datum.EMAIL)
      }
      
      return {
        ...datum,
        id
      }
    });

    errors = uniq(errors);

    if (errors.length > 0) {
      alert(`Attenzione!\nControllare i dati di questi indirizzi e-mail:\n\n${errors.join(',\n')}\n\nNon è stato possibile creare l'ID nella forma "SIGLA2email".`)
    }
    
    let ids = dataWithIDs.map((d)=>{
      return d.id
    })

    ids = compact(uniq(ids));

    let dataByIDs = ids.map((did)=>{
      return find(dataWithIDs,{id:did})
    })

    dataByIDs = dataByIDs.map((did)=>{
      let updatedDid = did;
      delete updatedDid.id;
      return updatedDid;
    })

    return dataByIDs;



  }

  const generateXLSX = (data) => {

    let rows = data;

    const fileName = `${Date.now()}-data.xlsx`;

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, fileName);
    
  }


  const getOutputData = () => {
    let updatedMasterFileData = updateMasterFileData();
    let outputData = [...newFileData, ...updatedMasterFileData];
    outputData = filterByCourseID(outputData);
    outputData = updateFirstNameAndLastName(outputData);

    let updatedOutputData = outputData.map((d) => omit(d, ["__parsed_extra"]));

    generateXLSX(updatedOutputData);
  }


  return (
    <div>
      <button onClick={getOutputData}>Compara i file caricati e scarica il file definitivo</button>
    </div>
  );
}

export default CompareData;

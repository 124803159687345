import { compact, find } from 'lodash';
import Papa from 'papaparse';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import docsConfig from '../General/docs_config.json';
import './DragNDrop.scss';

function DragNDrop(props) {
  const { id, parsedCsvData, setParsedCsvData, timeRange } = props;

  const getRecordsByTimeRange = (data, key) => {

    let updatedData = data;
    let header = data[0];
    let responseDateIndex = header.indexOf(key);

    updatedData = data.filter((d)=>{
      let responseDate = Date.parse(d[responseDateIndex]);
      let timeRangeValue = Date.parse(timeRange);
      return responseDate >= timeRangeValue;
    })

    updatedData.unshift(header);

    return updatedData;
    
  }

  const checkEmailsToDelete = (data, emailsToDelete, key) => {
  let header = data[0];
  let emailIndex = header.indexOf(key);
    data.forEach((d)=>{
      let checkEmailValue = emailsToDelete.indexOf(d[emailIndex]);

      if (checkEmailValue === 0) {
        alert(`Attezione!\nI seguenti indirizzi e-mail sono di persone di cui cancellare i dati:${d[emailIndex]}`)
      }
      
    })

  }

  const checkIfNumberOfColumsHasChanged = (header, correctColumns) => {

    let correctColumnsLength = correctColumns.length;
    let currentLength = header.length;

    if (currentLength !== correctColumnsLength) {
      let difference = header.filter(x => !correctColumns.includes(x));
      let differenceString = difference.join();

      alert(`Attenzione! Il numero delle colonne è cambiato!\nVecchio numero: ${correctColumnsLength},\nNuovo numero: ${currentLength}.\n I nomi delle nuove colonne sono: ${differenceString}`)
    }

  }


  const updateDataById = data => {

    let updatedData = data;

    let currentDocConfig = getCurrentDocConfigByID();
    const { notNullColumns, columnsToDelete, correctColumns, emailsToDelete } = currentDocConfig;



    switch (id) {
      case 'new':
        updatedData = deleteBlankRecords(updatedData);
        updatedData = deleteRecordsWithNullValuesInColumns(updatedData, notNullColumns);
        updatedData = deleteColumnsByIndexes(updatedData,columnsToDelete);
        checkIfNumberOfColumsHasChanged(updatedData[0],correctColumns);
        checkEmailsToDelete(updatedData,emailsToDelete,'EMAIL')
        break;
      case 'master':
        updatedData = deleteBlankRecords(updatedData);
        updatedData = getRecordsByTimeRange(updatedData,'REGISTRAZIONE');
        
        break;
        default:
          console.log('default');
          break;
        }

    return updatedData;

  }

  const deleteBlankRecords = data => {
    return data.filter((d)=>{
      let compactedData = compact(d);
      return compactedData.length!==0
    })
  }

  const deleteRecordsWithNullValuesInColumns = (data,notNullColumns) => {

    let updatedData = data;

    if (docsConfig !== undefined) {

      let valuesIndexes = notNullColumns.map((c)=> getIndexByValue(c,data));

      updatedData = data.filter((d)=>{
        let isNullValueInColumns = false;
        valuesIndexes.forEach((vi)=>{
          const { indexByValue } = vi;
          if(d[indexByValue] === "") {
            isNullValueInColumns = true;
          }
        })

        return isNullValueInColumns === false

      });
      
    }

    return updatedData;
  }

  const getCurrentDocConfigByID = () => {
    return find(docsConfig, {'id': id})
  }

  const deleteColumnsByIndexes = (data,columnsToDelete) => {
    let updatedData = data;

    if(docsConfig !== undefined) {

      let valuesIndexes = columnsToDelete.map((c)=> getIndexByValue(c,data));

      let dataWithoutColumns = [];
      
      updatedData.forEach((d)=>{
        let currentRecord = d.filter((e,i)=>{
          let isColumnToDelete = false;
          valuesIndexes.forEach((vi)=>{

            const { indexByValue } = vi;
            
            if (indexByValue === i ) {
              isColumnToDelete = true;
            }
            
          })
          
          return isColumnToDelete === false;
        });
        
        dataWithoutColumns.push(currentRecord)
        
      })
      
      updatedData = dataWithoutColumns;
    }


    return updatedData;
  }

  const getIndexByValue = (value, data) => {
    let header = data[0];
    return {
      id: value,
      indexByValue: header.indexOf(value)
    }
  }

 
  const completeFunction = useCallback(results => {

      
    let headerKey = `${id}-header`;
    let headerData = `${id}-data`;

    setParsedCsvData({
      ...parsedCsvData,
      [headerKey]: results.meta.fields,
      [headerData]: results.data,
    })


  },[id, parsedCsvData, setParsedCsvData]);
  
  const parseFile = file => {
    Papa.parse(file, {
      header: true,
      delimiter: ",",
      complete: completeFunction,
    });
  };



  const onDrop = acceptedFiles => {
    if (acceptedFiles.length) {
        const reader = new FileReader();
        const file = acceptedFiles[0];
        reader.readAsText(file, 'txt/csv');


        reader.onload = function() {

          const { result } = reader;
          var csvData = Papa.parse(result);
          const { data } = csvData;

          // filter csv data, add filters

          

          let updatedData = updateDataById(data);

          // back to csv, for file parse
          var csv = Papa.unparse(updatedData);


          const newFile = new File([csv], 'prefectures.txt', {
            type: 'text/csv',
          });

          parseFile(newFile);

        };

        reader.onerror = function() {
          console.log(reader.error);
        };

    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: 'text/csv',
  });
  

  return (
    <>
      <div
        {...getRootProps({
          className: `dropzone
          ${isDragAccept && 'dropzoneAccept'}
          ${isDragReject && 'dropzoneReject'}`,
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Trascina qui il file...</p>
        ) : (
          <p>Trascina qui il file o clicca per importarne uno dal computer</p>
        )}
      </div>
    </>
  );
}

export default DragNDrop;

import React, { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import CompareData from './CompareData';
import './CompareInputFilesSection.scss';
import DatePicker from './DatePicker';
import DragNDrop from './DragNDrop';




function CompareInputFilesSection() {

  const [parsedCsvData, setParsedCsvData] = useState({});
  const [timeRange, setTimeRange] = useState();

  let isNewDataParsed = parsedCsvData[`new-data`] !== undefined;
  let isMasterDataParsed = parsedCsvData[`master-data`] !== undefined;

  let downloadedFilesComparisonActive = (isNewDataParsed && isMasterDataParsed);
  let newFileData = {};
  let masterFileData = {};

  if (downloadedFilesComparisonActive === true) {
    newFileData =  parsedCsvData[`new-data`];
    masterFileData =  parsedCsvData[`master-data`];
  }

  return (
    <div className="CompareInputFilesSection">
      <div className="column">
        <p>Carica qui il file con le nuove registrazioni:</p>
        {isNewDataParsed ? (
          <p className="correct-text">
            File con le nuove registrazioni caricato correttamente!
          </p>
        ) : (
          <DragNDrop
            id={"new"}
            parsedCsvData={parsedCsvData}
            setParsedCsvData={setParsedCsvData}
          />
        )}
      </div>
      <div className="column">
        <p>Carica qui il file master.</p>
        <p>Seleziona l'intervallo di tempo desiderato:</p>
        <DatePicker startDate={timeRange} setStartDate={setTimeRange} />
        {timeRange &&
          (isMasterDataParsed ? (
            <p className="correct-text">
              File master caricato correttamente!
            </p>
          ) : (
            <DragNDrop
              id={"master"}
              parsedCsvData={parsedCsvData}
              setParsedCsvData={setParsedCsvData}
              timeRange={timeRange}
            />
          ))}
      </div>
      {downloadedFilesComparisonActive === true && (
        <CompareData
          newFileData={newFileData}
          masterFileData={masterFileData}
        />
      )}
    </div>
  );
}

export default CompareInputFilesSection;
